export const CHART_TYPES = {
  PieChart: 'PieChart',
  BarChart: 'BarChart',
  ColumnChart: 'ColumnChart',
  MsBarChart: 'MsBarChart',
  MsColumnChart: 'MsColumnChart',
  NumericChart: 'NumericChart',
};
const CHART_IMAGES = {
  PieChart: 'Pie 2D.png',
  BarChart: 'Bar Chart.png',
  ColumnChart: 'Column 2D.png',
  MsBarChart: 'MultiSeriese Bar 2D.png',
  MsColumnChart: 'Stacked Bar Chart.png',
  NumericChart: 'Numeric.png',
};

export const DATE_TYPE = {
  NoDateRange: 'NoDateRange',
  SingleDate: 'Single',
  ComparisonDate: 'Comparison',
};

export const PLOTTOOLTEXT_TYPE = {
  MacrosPercentage: '$value / $sum = $percentValue',
  CustomPercentage: 'N/D = %',
};

export const CHART_CATEGORY_TYPE = {
  EngagementStatistics: 0,
  HealthAssessmentStatistics: 1,
  BiometricStatistics: 2,
  CustomHaQuestionStatistics: 3,
};

export const haCustomQuestionChartSample = {
  chartId: null,
  chartName: null,
  dateRangeType: DATE_TYPE.SingleDate,
  chartType: CHART_TYPES.ColumnChart,
  description:
    'Displays the number of members who answered the selected custom HRA question per response in the filter date range.',
  sampleImage: CHART_IMAGES.ColumnChart,
  endpoint: 'ha-custom-question',
  chartSettings: {
    paletteColors: 'mkstandard',
    yaxisname: 'Members',
  },
  sampleData: [
    {
      label: 'Response 1',
      value: '10',
    },
    {
      label: 'Response 2',
      value: '25',
    },
    {
      label: 'Response 3',
      value: '15',
    },
  ],
};

export const chartLibraryItemsInternalList = [
  {
    chartCategoryId: CHART_CATEGORY_TYPE.EngagementStatistics,
    chartCategoryName: 'Engagement Statistics',
    charts: [
      {
        chartId: 0,
        chartName: 'Devices Connected by Device Type',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the number of devices connected by device type in the filter date range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'devices-connected-by-type',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          {
            label: 'Apple Health',
            value: 20,
          },
          {
            label: 'Garmin',
            value: 40,
          },
          {
            label: 'Apple Watch',
            value: 10,
          },
          {
            label: 'Fitbit',
            value: 30,
          },
        ],
      },
      {
        chartId: 1,
        chartName: 'Total Logins (#)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total number of logins in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'total-logins',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 789100,
      },
      {
        chartId: 112,
        chartName: 'Unique Logins (#)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the number of total unique logins in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'unique-logins',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 80000,
      },
      {
        chartId: 115,
        chartName: 'Total Logins (#)',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the total number of logins in the portal in the filter date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'total-logins-compare',
        chartSettings: {
          paletteColors: 'mkstandard',
          yaxisname: 'Logins',
        },
        sampleData: [
          {
            label: '1/1/2023 - 12/31/2023',
            value: '789000',
          },
          {
            label: '1/1/2022 - 12/31/2022',
            value: '567000',
          },
          {
            label: '1/1/2021 - 12/31/2021',
            value: '456000',
          },
        ],
      },
      {
        chartId: 2,
        chartName: 'Top 5 Challenges by Member Enrollment (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the top 5 challenges by the percentage of members who enrolled in the challenge in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-5-challenges-by-enrollment',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
          xaxisname: 'Members',
        },
        sampleData: [
          {
            label: 'Stress Less in the New Year Challenge 2024',
            value: 72,
          },
          {
            label: 'Unplug Those Devices Challenge 2024',
            value: 57,
          },
          {
            label: 'Go the Distance Miles Challenge 2024 - miles',
            value: 38,
          },
          {
            label: 'Stay Hydrated Team Challenge 2024',
            value: 44,
          },
          {
            label: 'Go Green Save the Planet Challenge',
            value: 25,
          },
        ],
      },
      {
        chartId: 3,
        chartName: 'Connected Device Rate (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who connected at least one device in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'connected-device-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
        },
        sampleData: 80,
      },
      {
        chartId: 8,
        chartName: 'Engagement by Key Activities (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the percentage of members who have registered, completed the HRA, completed a challenge, earned points, completed a program, connected a device, or joined a social circle in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'engagement-by-Key-activities',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          xaxisname: 'Members',
        },
        sampleData: [
          {
            label: 'Registered',
            value: 85,
          },
          {
            label: 'Completed the HRA',
            value: 75,
          },
          {
            label: 'Completed at least 1 challenge',
            value: 55,
          },
          {
            label: 'Completed a program',
            value: 65,
          },
          {
            label: 'Connected a device',
            value: 75,
          },
          {
            label: 'Joined a social circle',
            value: 80,
          },
        ],
      },
      {
        chartId: 10,
        chartName: 'Engagement by Key Activities (%)',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.MsBarChart,
        description:
          'Compare the percentage of members who have registered, completed the HRA, completed at least 1 challenge, earned points, completed a program, connected a device, or joined a social circle in the filter date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.MsBarChart,
        endpoint: 'engagement-by-Key-activities-ms',
        chartSettings: {
          paletteColors: 'mkstandard',
          xaxisname: 'Members',
        },
        categories: [
          {
            category: [
              {
                label: 'Registered',
              },
              {
                label: 'Completed the HRA',
              },
              {
                label: 'Completed at least 1 challenge',
              },
              {
                label: 'Completed a program',
              },
              {
                label: 'Connected a device',
              },
              {
                label: 'Joined a social circle',
              },
            ],
          },
        ],
        sampleData: [
          {
            seriesname: '1/1/2020 - 1/1/2022',
            data: [
              {
                value: '200',
              },
              {
                value: '100',
              },
              {
                value: '110',
              },
              {
                value: '70',
              },
              {
                value: '150',
              },
              {
                value: '20',
              },
            ],
          },
          {
            seriesname: '1/1/2022 - 1/1/2024',
            data: [
              {
                value: '350',
              },
              {
                value: '220',
              },
              {
                value: '210',
              },
              {
                value: '90',
              },
              {
                value: '110',
              },
              {
                value: '10',
              },
            ],
          },
        ],
      },
      {
        chartId: 104,
        chartName: 'Total Incentives Earned (points)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total points earned for all members in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'incentives-total-earned-points',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 30000,
      },
      {
        chartId: 105,
        chartName: 'Average Incentives Earned (points)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the average points earned per member in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'incentives-average-earned-points',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 150,
      },
      {
        chartId: 107,
        chartName: 'Earned Points Rate (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who earned points in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'incentives-earned-points-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 12.0,
      },
      {
        chartId: 108, //new charts
        chartName: 'Total Eligible Members (#)',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the total number of members in the portal in teh filter date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'total-eligible-members-compare',
        chartSettings: {
          paletteColors: 'mkstandard',
          yaxisname: 'Members',
        },
        sampleData: [
          {
            label: '1/1/2023 - 1/31/2023',
            value: '300000',
          },
          {
            label: '2/1/2023- 2/28/2023',
            value: '325000',
          },
          {
            label: '3/1/2023 - 3/31/2023',
            value: '350000',
          },
        ],
      },
      {
        chartId: 114,
        chartName: 'Total Eligible Members (#)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total number of eligible members in the portal in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'total-eligible-members',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 350000,
      },
      {
        chartId: 113,
        chartName: 'Average Minutes Spent in Portal (#)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the average time members spent in the portal in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'average-time-spent',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 35,
      },
      {
        chartId: 109,
        chartName: 'Login Method (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage of members that logged in by login method (browser, mobile, or both).',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'login-method',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          decimals: 0,
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          {
            label: 'Mobile only',
            value: 10,
          },
          {
            label: 'Desktop only',
            value: 50,
          },
          {
            label: 'Both login methods',
            value: 40,
          },
        ],
      },
      {
        chartId: 110,
        chartName: 'Gender Distribution (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the gender distribution of members in the filter date range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'gender-distribution',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          {
            label: 'Male',
            value: 30,
          },
          {
            label: 'Female',
            value: 62,
          },
          {
            label: 'Unspecified',
            value: 8,
          },
        ],
      },
      {
        chartId: 116,
        chartName: 'Member Type Distribution (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description:
          'Display the percentage of members by type (member, spouse, dependent) within the selected date range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'member-type',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          {
            label: 'Member',
            value: 75,
          },
          {
            label: 'Spouse',
            value: 20,
          },
          {
            label: 'Dependent (Child)',
            value: 4,
          },
          {
            label: 'Dependent (Family)',
            value: 1,
          },
        ],
      },
      {
        chartId: 111,
        chartName: 'Challenge Completion Rate (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who completed at least 1 challenge in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'challenge-completion-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 0.3,
      },
      {
        chartId: 131,
        chartName: 'Total Steps (#)',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the total steps for all members in the filter date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'tracker-total-steps',
        chartSettings: {
          paletteColors: 'mkstandard',
          yaxisname: 'Steps',
        },
        sampleData: [
          {
            label: '5/1/2024 - 5/31/2024',
            value: '1425000',
          },
          {
            label: '6/1/2024 - 6/30/2024',
            value: '1500000 ',
          },
          {
            label: '7/1/2024 - 7/31/2024',
            value: '1387500',
          },
        ],
      },
      {
        chartId: 132,
        chartName: 'Average Total Steps (#)',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the average total steps for all members in the filter date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'tracker-average-steps',
        chartSettings: {
          paletteColors: 'mkstandard',
          yaxisname: 'Steps',
        },
        sampleData: [
          {
            label: '5/1/2024 - 5/31/2024',
            value: '9500',
          },
          {
            label: '6/1/2024 - 6/30/2024',
            value: '7500',
          },
          {
            label: '7/1/2024 - 7/31/2024',
            value: '8250',
          },
        ],
      },
      {
        chartId: 133,
        chartName: 'Total Activity Minutes (#)',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the total activity minutes for all members in the filter date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'tracker-total-active-minutes',
        chartSettings: {
          paletteColors: 'mkstandard',
          yaxisname: 'Minutes',
        },
        sampleData: [
          {
            label: '5/1/2024 - 5/31/2024',
            value: '9500',
          },
          {
            label: '6/1/2024 - 6/30/2024',
            value: '7500',
          },
          {
            label: '7/1/2024 - 7/31/2024',
            value: '8250',
          },
        ],
      },
      {
        chartId: 134,
        chartName: 'Average Total Activity Minutes (#)',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the average total activity minutes for all members in the filter date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'tracker-average-active-minutes',
        chartSettings: {
          paletteColors: 'mkstandard',
          yaxisname: 'Minutes',
        },
        sampleData: [
          {
            label: '5/1/2024 - 5/31/2024',
            value: '101',
          },
          {
            label: '6/1/2024 - 6/30/2024',
            value: '56',
          },
          {
            label: '7/1/2024 - 7/31/2024',
            value: '97',
          },
        ],
      },
      {
        chartId: 117,
        chartName: 'Total Tango Dollars Spent ($)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the total Tango dollars spent in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'tango-total-dollars-spent',
        chartSettings: {
          paletteColors: 'mkstandard',
          numberprefix: '$',
        },
        sampleData: 30000,
      },
      {
        chartId: 118,
        chartName: 'Average Tango Dollars Spent ($)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the average Tango dollars spent per member who spent Tango dollars in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'tango-average-dollars-spent',
        chartSettings: {
          paletteColors: 'mkstandard',
          numberprefix: '$',
        },
        sampleData: 150,
      },
      {
        chartId: 120,
        chartName: 'New Registrations',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the number of new registrations of members (excluding spouses, dependents and disabled members) who have registered in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'new-registrations',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: 234,
      },
      {
        chartId: 121,
        chartName: 'Joined Social Circle Rate (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who have joined a social circle in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'joined-social-circle-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 2.5,
      },
      {
        chartId: 122,
        chartName: 'HRA Completion Rate (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who completed the Health Risk Assessment (HRA) in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'hra-completion-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 1.2,
      },
      {
        chartId: 123,
        chartName: 'Registration Rate (%)',
        dateRangeType: DATE_TYPE.NoDateRange,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members (excluding spouses, dependents and disabled members) who have registered as of the current date/time.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'registration-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 1.9,
      },
      {
        chartId: 124,
        chartName: 'Registration Rate Over Time',
        dateRangeType: DATE_TYPE.ComparisonDate,
        chartType: CHART_TYPES.ColumnChart,
        description:
          'Displays the rate of registration in the filter date range. Each series represents a different date range, allowing for easy comparison between time periods.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'registration-rate-ms',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
          yaxisname: 'Members',
        },
        sampleData: [
          {
            label: '5/1/2024 - 5/31/2024',
            value: 55,
          },
          {
            label: '6/1/2024 - 6/30/2024',
            value: 57,
          },
          {
            label: '7/1/2024 - 7/31/2024',
            value: 63,
          },
        ],
      },
      {
        chartId: 125,
        chartName: 'Program Completion Rate (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who completed a program in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'program-completion-rate',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 0.2,
      },
    ],
  },
  {
    chartCategoryId: CHART_CATEGORY_TYPE.HealthAssessmentStatistics,
    chartCategoryName: 'Health Assessment Statistics',
    charts: [
      {
        chartId: 100,
        chartName: 'HRA Completed by Age Group (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.ColumnChart,
        description: 'Displays the percentage of members who took the HRA by age group in the filter date range.',
        sampleImage: CHART_IMAGES.ColumnChart,
        endpoint: 'hra-by-age-group',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 0,
          numbersuffix: '%',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          yaxisname: 'Members',
        },
        sampleData: [
          {
            label: '16-17',
            value: 100,
          },
          {
            label: '18-29',
            value: 20,
          },
          {
            label: '30-39',
            value: 40,
          },
          {
            label: '40-49',
            value: 80,
          },
          {
            label: '50-59',
            value: 30,
          },
          {
            label: '60+',
            value: 60,
          },
        ],
      },
      {
        chartId: 101,
        chartName: 'HRA Completed by Gender (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage of members who took the HRA by gender in the filter date range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'hra-by-gender',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          {
            label: 'Male',
            value: 47,
          },
          {
            label: 'Female',
            value: 53,
          },
        ],
      },
      {
        chartId: 331,
        chartName: 'Members Registration Breakdown (%)',
        dateRangeType: DATE_TYPE.NoDateRange, // Assuming SingleDate for current date/time
        chartType: CHART_TYPES.PieChart,
        description:
          'Displays the percentage of members who have completed the HRA, registered but not completed the HRA, and not registered as of the current date/time.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'hra-by-registration',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          {
            label: 'Completed HRA',
            value: 61,
          },
          {
            label: 'Registered, HRA not completed',
            value: 24,
          },
          {
            label: 'Didn’t register',
            value: 15,
          },
        ],
      },
      {
        chartId: 102,
        chartName: 'Meet Exercise Recommendations by BMI Class (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the percentage of members who meet exercise recommendations by BMI class in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'members-bmi-class',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
          xaxisname: 'Members',
        },
        sampleData: [
          {
            label: 'Underweight (<18.5)',
            value: 3,
          },
          {
            label: 'Normal (18.5-24.9)',
            value: 12,
          },
          {
            label: 'Overweight (25-29.9)',
            value: 46,
          },
          {
            label: 'Obese (Class 1/Class II - 30-39.9)',
            value: 35,
          },
          {
            label: 'Extremely Obese (Class III >=40)',
            value: 4,
          },
        ],
      },
      {
        chartId: 103,
        chartName: 'Self-Reported Health Status Breakdown (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage of members by self-reported health status in the filter date range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'self-reported-health-status',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
          showLabels: '0',
        },
        sampleData: [
          {
            label: 'Poor',
            value: 13,
          },
          {
            label: 'Fair',
            value: 25,
          },
          {
            label: 'Good',
            value: 38,
          },
          {
            label: 'Excellent',
            value: 16,
          },
          {
            label: 'Unreported',
            value: 8,
          },
        ],
      },
      {
        chartId: 301,
        chartName: 'Self-Reported Health Is the Same or Better (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members whose self-reported health is the same or better this year in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/is-same-or-better',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 51,
      },
      {
        chartId: 302,
        chartName: 'Meet Exercise Recommendations (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who meet exercise recommendations in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/meet-exercise-recommendations',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 49,
      },
      {
        chartId: 303,
        chartName: 'Interested in Exercising More (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who are interested in exercising more in the future in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/interested-in-exercising-more',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 33,
      },
      {
        chartId: 304,
        chartName: 'Less Than 6oz Sugary Beverages Per Day (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who normally drink 6 oz of sugar sweetened beverages a day in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/less-than-6oz-sugary-beverages-per-day',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 24,
      },
      {
        chartId: 305,
        chartName: 'Eat 5+ Fruits and Veggies Per Day (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who eat 5+ fruits and veggies a day in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/eat-5-fruits-and-veggies-per-day',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 33,
      },
      {
        chartId: 306,
        chartName: 'Eat Fried Foods or Pastries Most Days (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who eat fried foods or pastries on most days in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/eat-fried-foods-pastries-most-days',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 55,
      },
      {
        chartId: 307,
        chartName: 'Eat Fast Food > 3 Days Per Week (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who eat fast food more than 3 days/week in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/eat-fast-food-3-days-per-week',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 45,
      },
      {
        chartId: 308,
        chartName: 'Floss Regularly (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who regularly floss in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/floss-regularly',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 22,
      },
      {
        chartId: 309,
        chartName: 'Dental Checkups in Last 6 Months (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who had dental checkups in the last 6 months in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/dental-checkups-in-last-6-months',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 41,
      },
      {
        chartId: 310,
        chartName: 'Brush 2x Per Day (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who brush twice a day in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/brush-2x-per-day',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 65,
      },
      {
        chartId: 311,
        chartName: 'Drink Occasionally or Less (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who drink occasionally or less in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/drink-occasionally-or-less',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 73,
      },
      {
        chartId: 312,
        chartName: 'Do Not Use Tobacco (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who do not use tobacco in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/do-not-use-tobacco',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 32,
      },
      {
        chartId: 313,
        chartName: 'Sleep Soundly, Feel Rested (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who sleep soundly, feel rested in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/sleep-soundly-feel-rested',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 65,
      },
      {
        chartId: 314,
        chartName: 'Sick Days in Last 12 Months Due to Stress (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who took sick days in the last 12 months due to stress in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/sick-days-last-12-months-stress',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 21,
      },
      {
        chartId: 315,
        chartName: 'Emotional State Caused Missing Daily Activities (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who said emotional state caused them to miss out on daily activities in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/emotional-state-caused-daily-activities',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 13,
      },
      {
        chartId: 316,
        chartName: 'High Risk for Stress (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who are at high risk for stress in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/high-risk-for-stress',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 41,
      },
      {
        chartId: 317,
        chartName: 'High Risk for Lack of Exercise (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who are at high risk for lack of exercise in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/high-risk-for-lack-of-exercise',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 31,
      },
      {
        chartId: 318,
        chartName: 'High Risk for Tobacco Use (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who are at high risk for tobacco use in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/high-risk-for-tobacco-use',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 55,
      },
      {
        chartId: 319,
        chartName: 'Completed Preventative Care by Screening Type (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the percentage of members who completed preventative care by screening type in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'completed-preventative-care-by-type',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          xaxisname: 'Members',
        },
        sampleData: [
          {
            label: 'Bone Density Check',
            value: 50,
          },
          {
            label: 'Breast Cancer gene test (BRCA1 and BRCA2)',
            value: 40,
          },
          {
            label: 'Abdominal Aortic Aneurysm Screening',
            value: 33,
          },
          {
            label: 'Digital Rectal Exam',
            value: 12,
          },
          {
            label: 'Colorectal Cancer Screening ',
            value: 11,
          },
          {
            label: 'Cholesterol panel screening',
            value: 5,
          },
          {
            label: 'Tuberculosis (TB) Test',
            value: 20,
          },
          {
            label: 'Cervical Cancer Screening ',
            value: 3,
          },
          {
            label: 'Prostate-specific antigen (PSA) blood test',
            value: 22,
          },
          {
            label: 'Full skin exam',
            value: 17,
          },
          {
            label: 'Diabetes check',
            value: 44,
          },
          {
            label: 'Hepatitis C screening',
            value: 20,
          },
          {
            label: 'Mammogram',
            value: 55,
          },
        ],
      },
      {
        chartId: 320,
        chartName: 'Completed Immunizations by Type (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description: 'Displays the percentage of members who completed immunizations by type in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'completed-immunizations-by-type',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          xaxisname: 'Members',
        },
        sampleData: [
          {
            label: 'Hepatitis A Series',
            value: 33,
          },
          {
            label: 'Hepatitis B Series',
            value: 70,
          },
          {
            label: 'Human Papillomavirus (HPV) Series',
            value: 22,
          },
          {
            label: 'Flu',
            value: 21,
          },
          {
            label: 'Meningococcal/Meningitis Series',
            value: 40,
          },
          {
            label: 'Pneumococcal polysaccharide (PCV13/Prevnar13 or PPSV23/Pneumovax)',
            value: 70,
          },
          {
            label: 'Tetanus, diphtheria, and acellular pertussis (TDaP/TD)',
            value: 35,
          },
          {
            label: 'Zoster/Shingles Series',
            value: 25,
          },
          {
            label: 'Booster for Tetanus, Diphtheria, and acellular Pertussis (TDaP or TD)',
            value: 70,
          },
          {
            label: 'COVID-19 Primary Series',
            value: 75,
          },
          {
            label: 'COVID-19 Booster(s)',
            value: 45,
          },
        ],
      },
      {
        chartId: 321,
        chartName: 'High Risk for Each Disease Evaluated in the HRA (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the percentage of members at high risk for each HRA evaluated disease in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'high-risk-disease-evaluated',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
        },
        sampleData: [
          {
            label: 'Breast Cancer',
            value: 65,
          },
          {
            label: 'Colon Cancer',
            value: 40,
          },
          {
            label: 'Lung Cancer',
            value: 40,
          },
          {
            label: 'Melanoma',
            value: 30,
          },
          {
            label: 'Ovarian Cancer',
            value: 30,
          },
          {
            label: 'Uterine Cancer',
            value: 30,
          },
          {
            label: 'Prostate Cancer',
            value: 30,
          },
          {
            label: 'Cervical Cancer',
            value: 35,
          },
          {
            label: 'Oral and Throat Cancer',
            value: 65,
          },
          {
            label: 'Diabetes',
            value: 70,
          },
          {
            label: 'Heart Disease',
            value: 25,
          },
          {
            label: 'Ischemic Stroke',
            value: 60,
          },
          {
            label: 'Osteoporosis',
            value: 80,
          },
          {
            label: 'COPD',
            value: 45,
          },
          {
            label: 'Dental Health',
            value: 50,
          },
          {
            label: 'Depression',
            value: 60,
          },
        ],
      },
      {
        chartId: 322,
        chartName: 'Stress Affected Work Productivity (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description:
          'Displays the percentage of members who said stress affected work productivity in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'ha/level-of-stress-productivity',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 17,
      },
      {
        chartId: 323,
        chartName: 'Population Health Meter (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage of members in each HRA Results Health category in the filter date range.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'population-health-meter',
        chartSettings: {
          paletteColors: 'mkstandard',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          {
            label: 'Healthy',
            value: 450,
          },
          {
            label: 'Borderline',
            value: 350,
          },
          {
            label: 'Unhealthy',
            value: 200,
          },
        ],
      },
      {
        chartId: 324,
        chartName: 'High Risk for Depression (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.NumericChart,
        description: 'Displays the percentage of members who are at high risk for depression in the filter date range.',
        sampleImage: CHART_IMAGES.NumericChart,
        endpoint: 'high-risk-depression',
        chartSettings: {
          paletteColors: 'mkstandard',
          decimals: 1,
          numbersuffix: '%',
        },
        sampleData: 22,
      },
      {
        chartId: 325,
        chartName: 'Top 10 Self-Reported Conditions (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description: 'Displays the top 10 self-reported conditions in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-10-reported-conditions/all',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
        },
        sampleData: [
          {
            label: 'High Blood Pressure',
            value: 50,
          },
          {
            label: 'High Cholesterol and/or Triglycerides',
            value: 45,
          },
          {
            label: 'Diabetes Type 2',
            value: 40,
          },
          {
            label: 'Depression',
            value: 35,
          },
          {
            label: 'Diabetes Type 1',
            value: 25,
          },
          {
            label: `Crohn's Disease`,
            value: 20,
          },
          {
            label: 'Plantar Fasciitis',
            value: 15,
          },
          {
            label: 'Polycystic Ovarian Syndrome',
            value: 10,
          },
          {
            label: 'Carpal Tunnel Syndrome',
            value: 5,
          },
        ],
      },
      {
        chartId: 326,
        chartName: 'Top 10 Self-Reported Conditions for Females (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description: 'Displays the top 10 self-reported conditions in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-10-reported-conditions/females',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
        },
        sampleData: [
          {
            label: 'High Blood Pressure',
            value: 50,
          },
          {
            label: 'High Cholesterol and/or Triglycerides',
            value: 45,
          },
          {
            label: 'Diabetes Type 2',
            value: 40,
          },
          {
            label: 'Depression',
            value: 35,
          },
          {
            label: 'Diabetes Type 1',
            value: 25,
          },
          {
            label: `Crohn's Disease`,
            value: 20,
          },
          {
            label: 'Plantar Fasciitis',
            value: 15,
          },
          {
            label: 'Polycystic Ovarian Syndrome',
            value: 10,
          },
          {
            label: 'Carpal Tunnel Syndrome',
            value: 5,
          },
        ],
      },
      {
        chartId: 327,
        chartName: 'Top 10 Self-Reported Conditions for Males (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description: 'Displays the top 10 self-reported conditions in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-10-reported-conditions/males',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
        },
        sampleData: [
          {
            label: 'High Blood Pressure',
            value: 50,
          },
          {
            label: 'High Cholesterol and/or Triglycerides',
            value: 45,
          },
          {
            label: 'Diabetes Type 2',
            value: 40,
          },
          {
            label: 'Depression',
            value: 35,
          },
          {
            label: 'Diabetes Type 1',
            value: 25,
          },
          {
            label: `Crohn's Disease`,
            value: 20,
          },
          {
            label: 'Plantar Fasciitis',
            value: 15,
          },
          {
            label: 'Polycystic Ovarian Syndrome',
            value: 10,
          },
          {
            label: 'Carpal Tunnel Syndrome',
            value: 5,
          },
        ],
      },
      {
        chartId: 328,
        chartName: 'Top 5 Stressors (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description: 'Displays the top 5 stressors in the report range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-5-stressors/all',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
        },
        sampleData: [
          {
            label: 'Financial Worries',
            value: 35,
          },
          {
            label: 'You are a caregiver',
            value: 20,
          },
          {
            label: 'Concern over family life',
            value: 15,
          },
          {
            label: 'You are overworked',
            value: 10,
          },
          {
            label: 'Family member or close friend is sick',
            value: 5,
          },
        ],
      },
      {
        chartId: 329,
        chartName: 'Top 5 Stressors for Females (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description: 'Displays the top 5 stressors for females in the report range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-5-stressors/females',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
        },
        sampleData: [
          {
            label: 'Financial Worries',
            value: 35,
          },
          {
            label: 'You are a caregiver',
            value: 20,
          },
          {
            label: 'Concern over family life',
            value: 15,
          },
          {
            label: 'You are overworked',
            value: 10,
          },
          {
            label: 'Family member or close friend is sick',
            value: 5,
          },
        ],
      },
      {
        chartId: 330,
        chartName: 'Top 5 Stressors for Males (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description: 'Displays the top 5 stressors for males in the report range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'top-5-stressors/males',
        chartSettings: {
          paletteColors: 'mkstandard',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
        },
        sampleData: [
          {
            label: 'Financial Worries',
            value: 35,
          },
          {
            label: 'You are a caregiver',
            value: 20,
          },
          {
            label: 'Concern over family life',
            value: 15,
          },
          {
            label: 'You are overworked',
            value: 10,
          },
          {
            label: 'Family member or close friend is sick',
            value: 5,
          },
        ],
      },
      {
        chartId: 335,
        chartName: 'Average Stress Level by Gender',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description: 'Displays the average stress level of members by gender in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'average-stress-level-gender',
        chartSettings: {
          paletteColors: 'mkstandard',
        },
        sampleData: [
          {
            label: 'Male',
            value: 2.2,
          },
          {
            label: 'Female',
            value: 3.7,
          },
        ],
      },
      {
        chartId: 336,
        chartName: 'Stress Affected Work Productivity by Gender (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.BarChart,
        description:
          'Displays the percentage of members who said stress affected work productivity by gender in the filter date range.',
        sampleImage: CHART_IMAGES.BarChart,
        endpoint: 'stress-affected-work-productivity-gender',
        chartSettings: {
          paletteColors: 'mkstandard',
          numbersuffix: '%',
        },
        sampleData: [
          {
            label: 'Male',
            value: 18,
          },
          {
            label: 'Female',
            value: 62,
          },
        ],
      },
    ],
  },
  {
    chartCategoryId: CHART_CATEGORY_TYPE.BiometricStatistics,
    chartCategoryName: 'Biometric Statistics',
    charts: [
      {
        chartId: 332,
        chartName: 'BMI by Class (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by BMI Class.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-bmi-by-class',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Underweight', value: 5, dataValue: 50 },
          { label: 'Normal', value: 29, dataValue: 290 },
          { label: 'Overweight', value: 32, dataValue: 320 },
          { label: 'Obese (Class I/Class II)', value: 27, dataValue: 270 },
          { label: 'Extremely Obese (Class III)', value: 7, dataValue: 70 },
        ],
      },
      {
        chartId: 333,
        chartName: 'Total Cholesterol by Category (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by Total Cholesterol Category.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-total-cholesterol-by-category',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Desirable/Optimal', value: 44, dataValue: 440 },
          { label: 'Borderline High', value: 32, dataValue: 320 },
          { label: 'High', value: 24, dataValue: 240 },
        ],
      },
      {
        chartId: 334,
        chartName: 'HDL by Category (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by HDL Category.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-hdl-by-category',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Low/Undesirable', value: 24, dataValue: 240 },
          { label: 'Near Optimal', value: 32, dataValue: 320 },
          { label: 'Desirable/Optimal', value: 44, dataValue: 440 },
        ],
      },
      {
        chartId: 341,
        chartName: 'LDL by Category (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by LDL Category.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-ldl-by-category',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Desirable/Optimal', value: 40, dataValue: 400 },
          { label: 'Near Optimal', value: 28, dataValue: 280 },
          { label: 'Borderline High', value: 18, dataValue: 180 },
          { label: 'High', value: 12, dataValue: 120 },
          { label: 'Very High', value: 2, dataValue: 20 },
        ],
      },
      {
        chartId: 342,
        chartName: 'Triglycerides by Category (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by Triglycerides Category.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-triglycerides-by-category',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Desirable/Optimal', value: 40, dataValue: 400 },
          { label: 'Borderline High', value: 38, dataValue: 380 },
          { label: 'High', value: 12, dataValue: 120 },
          { label: 'Very High', value: 10, dataValue: 100 },
        ],
      },
      {
        chartId: 343,
        chartName: 'TC/HDL by Category (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by TC/HDL Category.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-tc-hdl-by-category',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Good', value: 57, dataValue: 570 },
          { label: 'Bad', value: 43, dataValue: 430 },
        ],
      },
      {
        chartId: 344,
        chartName: 'Blood Sugar (FG) by Category (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by Blood Sugar (FG) Category.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-blood-sugar-fg-by-category',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Normal', value: 53, dataValue: 530 },
          { label: 'Borderline', value: 32, dataValue: 320 },
          { label: 'High', value: 15, dataValue: 150 },
        ],
      },
      {
        chartId: 345,
        chartName: 'Systolic BP by Category (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by Systolic BP Category.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-systolic-bp-by-category',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Normal', value: 53, dataValue: 530 },
          { label: 'Elevated', value: 12, dataValue: 120 },
          { label: 'High, Stage 1', value: 15, dataValue: 150 },
          { label: 'High, Stage 2', value: 10, dataValue: 100 },
          { label: 'Hypertensive Crisis', value: 10, dataValue: 100 },
        ],
      },
      {
        chartId: 346,
        chartName: 'Diastolic BP by Category (%)',
        dateRangeType: DATE_TYPE.SingleDate,
        chartType: CHART_TYPES.PieChart,
        description: 'Displays the percentage and total number of members by Diastolic BP Category.',
        sampleImage: CHART_IMAGES.PieChart,
        endpoint: 'bio-diastolic-bp-by-category',
        chartSettings: {
          paletteColors: 'mkvalue',
          showLabels: '0',
          plotToolText: PLOTTOOLTEXT_TYPE.CustomPercentage,
          formatNumberScale: '0',
        },
        sampleData: [
          { label: 'Normal', value: 53, dataValue: 530 },
          { label: 'High, Stage 1', value: 15, dataValue: 150 },
          { label: 'High, Stage 2', value: 20, dataValue: 200 },
          { label: 'Hypertensive Crisis', value: 12, dataValue: 120 },
        ],
      },
    ],
  },
  {
    chartCategoryId: CHART_CATEGORY_TYPE.CustomHaQuestionStatistics,
    chartCategoryName: 'Custom HA Question Statistics',
    charts: [],
  },
];
